<template>
	<div class="zoom-container">
	  <b-tabs v-model="selected" class="paddingless mb-0">
		<b-tab-item
		  v-for="(view, i) in slides"
		  :key="i"
		  :value="i.toString()"
		>
		  <img
			ref="main"
			:src="view.url + view.path + view.filename +'-w1000h1000.'+ view.version + '.webp'"
			:alt="view.label"
			width="1000"
            height="1000"
			class="hero"
		  />
		</b-tab-item>
	  </b-tabs>
  
	  <div v-if="slides.length > 1" class="wrap">
		<span class="prev" @click.stop="prev">
		  <b-icon icon="chevron-left" class="pb-3" size="is-medium"/>
		</span>
		<span class="next" @click.stop="next">
		  <b-icon icon="chevron-right" class="pb-3" size="is-medium"/>
		</span>
	  </div>
  
	  <ul class="views">
		<li
		  v-for="(view, n) in slides"
		  :key="n"
		  :class="selected == n ? 'active' : null"
		  @click.stop="selectImage(n)"
		>
		  <img :src="view.url + view.path  + view.filename +'-w58h58.'+ view.version + '.webp'" :alt="view.label" width="80"
            height="80" />
		</li>
	  </ul>
	</div>
  </template>
  
  <script>
  export default {
	props: {
	  slides: {
		type: Array,
		required: true,
	  },
	  name: {
		type: String,
		required: true,
	  },
	  window: {
		type: Number,
		required: true,
	  },
	},
	data() {
	  return {
		windowWidth: 0,
		selected: this.window,
	  };
	},
	mounted() {
	  if (window) {
		this.windowWidth = this.getScreenSize(
		  window.innerWidth,
		  window.innerHeight
		);
		window.addEventListener("resize", () => {
		  this.windowWidth = this.getScreenSize(
			window.innerWidth,
			window.innerHeight
		  );
		});
	  }
	},
	methods: {
	  next() {
		this.selected =
		  this.selected + 1 === this.slides.length ? 0 : this.selected + 1;
	  },
	  prev() {
		this.selected =
		  this.selected - 1 < 0 ? this.slides.length - 1 : this.selected - 1;
	  },
	  getScreenSize(w, h) {
		let size = 0;
		if (w > h) size = h;
		else size = w;
		return size > 1000 ? 1000 : size;
	  },
	  selectImage(index) {
		this.selected = index;
	  },
	},
  };
  </script>
  